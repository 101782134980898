import { Box } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import cyberwaspLogo from '../../../assets/svg/cwLogo.svg'


const Logo = () => {

  const navigate = useNavigate()
  return (
        <Box
          display="flex"
          justifyContent="flex-start"
          className="logo"
          sx={{ cursor: "pointer",width:{xs:"100%",sm:"40%"}}}
          onClick={() => navigate("/")}
        >
          <img
            src={cyberwaspLogo}
            alt="cybersophy logo"
            style={{ width: "10rem", height: "auto" }}
          />
        </Box>
      );
}

export default Logo
