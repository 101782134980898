// // import { useEffect, useState } from "react";


// // import { Box, Card, CardContent, CircularProgress, Typography } from "@mui/material";
// // import PreLoader from "../../../../components/common/preLoader";
// // import { formatNumber } from "../../utils/formatNumber";
// // import { LoadingDots } from "../DotsLoader";



// // export const GRADIENT_COLORS = ['#FFFFFF', '#BED9E3'];

// // export const VerticalCard = ({ isLoading, title, number, imageUrl, onClick }) => {
// //   const isAttacksBlocked = title === "Attacks Blocked";
// //   const isPer = title === 'Attack Percentage';

// //   return (
// //     <Card
// //       onClick={onClick}
// //       sx={{
// //         cursor: isAttacksBlocked ? "pointer" : "default",
// //         flexGrow: 1,
// //         width: "100%",
// //         pl: 2,
// //         height: "6rem", // Adjust the height as per your requirement
// //         mr: title === "Total Hits" ? 2 : 0,
// //         borderBottom: "1px solid #B4DAE6",
// //         borderRadius: "0.5rem",
// //         background: `linear-gradient(to right, ${GRADIENT_COLORS.join(',')})`,
// //         color: '#000',
// //         display: 'flex',
// //         flexDirection: "row",
// //         justifyContent: isPer ? 'center' : 'flex-start',
// //         alignItems: "center",
// //         boxShadow: 'none',
// //         "&:hover": {
// //           boxShadow: isAttacksBlocked ? "0 0 10px rgba(0, 0, 0, 0.9)" : "none",
// //           color: isAttacksBlocked ? "#29A5D4" : "#000",
// //         },
// //       }}
// //     >
// //       <img src={imageUrl} className={"IconImage"} alt={title} style={{ marginRight: "10px" }} /> {/* Adjust margin here */}
// //       <CardContent
// //         sx={{
// //           display: "flex",
// //           flexDirection: "column",
// //           alignItems:"center",
// //           justifyContent:"center",
// //           height:"5rem",
// //           border:"1px solid red",
// //           // justifyContent: "center",
// //           // alignItems: "flex-start", // Align contents to start to prevent shrinking
// //         }}
// //       >
// //          {isLoading ? (
// //           <Typography lineHeight={"1.2rem"} variant="h2" sx={{ }}>{title}</Typography>
// //         ) : (
// //           <Typography lineHeight={"1.2rem"} variant="h2" sx={{  }}>{title}</Typography>
// //         )}
// //         {/* <Typography lineHeight={"1.2rem"} variant="h2" sx={{ marginBottom: '0.5rem' }}>{title}</Typography> */}
// //         {isLoading ? <LoadingDots text="" /> : (
// //           <Typography sx={{textAlign:"center"}} variant="h1" title={number}>
// //             {isPer ? `${number}%` : formatNumber(number)}
// //           </Typography>
// //         )}
// //       </CardContent>
// //     </Card>
// //   );
// // };


import { useEffect, useState } from "react";
 
 
import { Box, Card, CardContent, CircularProgress, Typography } from "@mui/material";
import PreLoader from "../../../../components/common/preLoader";
import { formatNumber } from "../../utils/formatNumber";
import { LoadingDots } from "../DotsLoader";
 
 
 
export const GRADIENT_COLORS = ['#FFFFFF', '#BED9E3'];
 
export const VerticalCard = ({ isLoading, title, number, imageUrl, onClick }) => {
  const isAttacksBlocked = title === "Attacks Blocked";
  const isPer = title === 'Attack Percentage';
 
  return (
    <Card
      onClick={onClick}
      sx={{
        cursor: isAttacksBlocked ? "pointer" : "default",
        // flexGrow: 1,
        // width: "100%",
        // pl: 2,
        // height: "8rem", // Adjust the height as per your requirement
        // mr: title === "Total Hits" ? 2 : 0,
        // borderBottom: "1px solid #B4DAE6",
        borderRadius: "0.5rem",
        background: `linear-gradient(to right, ${GRADIENT_COLORS.join(',')})`,
        color: '#000',
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: "center",gap:'0.6rem',p:"0.8rem 0rem 0.5rem 0rem",
        boxShadow: 'none',
        "&:hover": {
          boxShadow: isAttacksBlocked ? "0 0 10px rgba(0, 0, 0, 0.9)" : "none",
          color: isAttacksBlocked ? "#29A5D4" : "#000",
        },
      }}
    >
      <img src={imageUrl} className={"IconImage"} alt={title} /> {/* Adjust margin here */}
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height:"3rem",
          padding: "0px 0px 0px 0px !important",
          gap:'0.3rem',
          alignItems: "center", // Align contents to start to prevent shrinking
        }}
      >
         {isLoading ? (
          <Typography lineHeight={"1rem"} variant="h2">{title}</Typography>
        ) : (
          <Typography lineHeight={"1rem"} variant="h2">{title}</Typography>
        )}
        {/* <Typography lineHeight={"1.2rem"} variant="h2" sx={{ marginBottom: '0.5rem' }}>{title}</Typography> */}
        {isLoading ? <LoadingDots text="" /> : (
          <Typography sx={{textAlign:"center"}} variant="h1" title={number}>
            {isPer ? `${number}%` : formatNumber(number)}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};



// import { useEffect, useState } from "react";
 
 
// import { Box, Card, CardContent, CircularProgress, Typography } from "@mui/material";
// import PreLoader from "../../../../components/common/preLoader";
// import { formatNumber } from "../../utils/formatNumber";
// import { LoadingDots } from "../DotsLoader";
 
 
 
// export const GRADIENT_COLORS = ['#FFFFFF', '#BED9E3'];
 
// export const VerticalCard = ({ isLoading, title, number, imageUrl, onClick }) => {
//   const isAttacksBlocked = title === "Attacks Blocked";
//   const isPer = title === 'Attack Percentage';
 
//   return (
//     <Card
//       onClick={onClick}
//       sx={{
//         cursor: isAttacksBlocked ? "pointer" : "default",
//         // flexGrow: 1,
//         // width: "100%",
//         // pl: 2,
//         height: "6rem", // Adjust the height as per your requirement
//         // mr: title === "Total Hits" ? 2 : 0,
//         // borderBottom: "1px solid #B4DAE6",
//         // borderRadius: "0.5rem",
//         // background: `linear-gradient(to right, ${GRADIENT_COLORS.join(',')})`,
//         backgroundColor: "pink",
//         color: '#000',
//         display: 'flex',
//         flexDirection: "column",
//         gap: "0.2rem",
//         // justifyContent: isPer ? 'center' : 'flex-start',
//         alignItems: "center",
//         boxShadow: 'none',
//         "&:hover": {
//           boxShadow: isAttacksBlocked ? "0 0 10px rgba(0, 0, 0, 0.9)" : "none",
//           color: isAttacksBlocked ? "#29A5D4" : "#000",
//         },
//       }}
//     >
//       <img src={imageUrl} className={"IconImage"} alt={title} style={{ border: "solid 1px red" }} /> {/* Adjust margin here */}
//       <CardContent
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           // height:"5rem",
//           border: "solid 1px blue",
//           padding: "0px 0px 0px 0px !important",
//           // justifyContent: "center",
//           // alignItems: "flex-start", // Align contents to start to prevent shrinking
//         }}
//       >
//          {isLoading ? (
//           <Typography lineHeight={"1.2rem"} variant="h2">{title}</Typography>
//         ) : (
//           <Typography lineHeight={"1.2rem"} variant="h2">{title}</Typography>
//         )}
//         {/* <Typography lineHeight={"1.2rem"} variant="h2" sx={{ marginBottom: '0.5rem' }}>{title}</Typography> */}
//         {isLoading ? <LoadingDots text="" /> : (
//           <Typography sx={{textAlign:"center"}} variant="h1" title={number}>
//             {isPer ? `${number}%` : formatNumber(number)}
//           </Typography>
//         )}
//       </CardContent>
//     </Card>
//   );
// };
 