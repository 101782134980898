import { Box, Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCardData } from "../../../../redux/ui/uiActions";
import { fetchAttackBlockedPopUp, fetchLocationData, hitsAndAttacksApi } from "../../utils/apis";
import { initialCardsData } from "../../utils/cardsData";
import ButtonWithUnderline from "../ButtonWithUnderline";
import MapChart from "../geo/MapChart";
import { CountryList } from "./CountryList";
import { GradientCards } from "./GradientCards";
import PopupComponent from "../PopUpComponent";
import MyComponent from "../../Test";


const CardsComponent = ({startAndEndDate,domainName,timeLabel,params,setFilterValue,timeDifference}) => {


    let { cardsData,attacksBlockedData,heatMapData } = useSelector((state: any) => state.ui);
    let { isLoadindAttacksBlockedPopup } = useSelector((state: any) => state.loaders);

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [page,setPage]= useState(1)
    const [rowsPerPage,setRowsPerPage]=useState(20)
  
      const [activeOption, setActiveOption] = useState('blocked');

      // console.log("activeOption ",activeOption)

      const  dispatch = useDispatch()
  
  
    const handleChangePage = (event, newPage) => setPage(newPage);
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(1);
    };

  
    const handlePopupOpen = ()=>{
      setIsPopupOpen(true)
    }
    const handleClosePopUp = () =>{
      setIsPopupOpen(false)
  
    }

   
  
      useEffect(()=>{

      if(timeDifference > 1){
        if(domainName!==undefined){
            hitsAndAttacksApi(dispatch,params)
        }
          
        const updatedCardsData = initialCardsData?.map((item:any) => {
          return { ...item,isLoading:true };  
        });
    
        dispatch(setCardData(updatedCardsData))
      }
        
      },[startAndEndDate,domainName,timeLabel])   
    
      useEffect(()=>{
        if(domainName!==undefined && timeDifference > 1){
           fetchLocationData(dispatch,activeOption,params)
        }
      
      },[startAndEndDate,domainName,timeLabel,activeOption])   
  
      useEffect(()=>{
        if(isPopupOpen){
          fetchAttackBlockedPopUp(dispatch,params,page,rowsPerPage)
        }
      },[isPopupOpen,page,rowsPerPage])
    
      const handleOptionClick = (option) => {
        setActiveOption(option);
      };

      return  (
        <Box sx={{ 
            height:'100%',
            display: 'flex',
            flexDirection:{xs:"column",md:"row"}, 
            gap: '1.2rem' }}>

          <PopupComponent 
            setFilterValue={setFilterValue}
            tableTitle={"Attacks Blocked"} tableId={"attacksBlockedPopUp"}
            isOpen={isPopupOpen} handleClose={handleClosePopUp}
            rowsPerPage={rowsPerPage} page={page} data={attacksBlockedData}
            handleChangeRowsPerPage={handleChangeRowsPerPage} handleChangePage={handleChangePage} 
            isLoading={isLoadindAttacksBlockedPopup}
          />
          <GradientCards cardsData={cardsData} handlePopupOpen={handlePopupOpen} countries={heatMapData}/>
          <Box 
            id="geoMap" sx={{
            flex: 1,
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor:"#FFFFFF",
            borderRadius:"0.5rem"
          }}>
            <Box sx={{
              position: 'absolute',
              top: '1rem',
              left: '1rem',
              display: 'flex',
              gap: '0.5rem', 

            }}>
              
              <ButtonWithUnderline
                text="Attacks"
                isActive={activeOption === 'blocked'}
                onClick={() => handleOptionClick('blocked')}
              />
              <ButtonWithUnderline
                text="Authorized Traffic"
                isActive={activeOption === 'allTraffic'}
                onClick={() => handleOptionClick('allTraffic')}
              />
            </Box>
            <MapChart traffic={activeOption} heatMapData={heatMapData} />
      </Box>
        </Box>
      )

     
    };
    
export default CardsComponent;
