import React, { useState, useEffect, useRef } from "react";
import { AgGridColumn,AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Box, Button, FormControl, Input, InputAdornment, Menu, MenuItem, Pagination, Select, Stack, Tab, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import InfoIcon from '../../../assets/svg/infoIcon.svg';
import { makeStyles } from '@material-ui/core';
import { handleExportCSV } from "./csvDownload";
import NoAttacksPage from "../components/NoAttacksPage";
import { convertToIST } from "../utils/convertTime";
import './aggridstyles.css'
import CustomDialogBox from "./CustomDialogBox";
import PreLoader from "../../../components/common/preLoader";
import { Close } from "@mui/icons-material";
import MoreInfo from "./MoreInfo";
import { useSelector } from "react-redux";
import moment from "moment";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'; // Import the icon component

const useStyles = makeStyles((theme) => ({
    root: {
      // border: "solid 2px yellow",
      width: '100%',
      overflow: 'hidden',  // Hide scroll
      // marginTop: '2%',
      // marginBottom: '2%',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: "1% 1.5% 1% 1.5%",
      color:"#198be3",
      // border: "solid 1px red"
    },
    tableContainer: {
      // border: "solid 2px green",
      maxHeight: 400,
      overflowY: 'auto',
      msOverflowStyle: 'none', // Hide scrollbar in IE
      '&::-webkit-scrollbar': {
        width: '0em',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'transparent', // Hide scrollbar in Chrome, Safari, etc.
      },
    },
    table: {
      width: "100%",
      minWidth:900
    },
    tableHeader: {
      position: 'sticky',
      top: 0,
      background: '#1FA7D3',
      zIndex: 1,
    },
    searchInput: {
      // marginLeft: theme.spacing(2),
      width:"40%",
    },

    exportButtons: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      // marginTop: theme.spacing(2),
      
    },
   
    ellipsisCell: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      minWidth: "0.8em",
      // maxWidth: '1.5em',
      paddingLeft: "1.5% !important",
      fontSize: "1rem !important"
    },
    infoIconCell: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
   
   
  }));


interface AgGridTableProps {
  tableId?:string;
  tableTitle?:string;
  setFilterValue?:any;
  page?:any;
  rowsPerPage?:any;
  data?:any;
  handleChangeRowsPerPage?:any;
  handleChangePage?:any;
  isLoading?:boolean;
  isPopUp?:boolean;
  handleClosePopUp?:any;
  setSelectedSearchColumn?:any;
  selectedSearchColumn?:string;
}

const AgGridTable = ({
  tableId,tableTitle,setFilterValue,page,rowsPerPage,data,handleChangeRowsPerPage,
  handleChangePage,setSelectedSearchColumn,selectedSearchColumn,isLoading,isPopUp,handleClosePopUp}:AgGridTableProps) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const [isPdfDialogOpen, setPdfDialogOpen] = useState(false);
 
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [selectedHeaders, setSelectedHeaders] = useState<any[]>([]);
  const { isWafAdmin } = useSelector((state: any) =>  state.authentication );

  const [searchTerm, setSearchTerm] = useState('');

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);

  const handleMenuClose = () => setAnchorEl(null);

  const handleChangeSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {

      // Call your API here with the search term
      if (searchTerm) {
        // API call with the search term
        setFilterValue(searchTerm)
      }
    }
  };

  const showRowDetails = (rowData) => {
    setSelectedRowData(rowData);
    setSelectedTabIndex(0); // Reset tab index to show the first tab initially
    setDrawerOpen(true); // Open the drawer when details are shown
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
    setSelectedRowData(null); // Reset selected data when drawer is closed
  };



  
  const classes = useStyles()


  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage - 1, data?.total - 1);

  const displayedRows = data?.rows?.slice(startIndex, endIndex + 1);


  useEffect(() => {
    // Calculate the required height based on the number of rows
    const rowCount = 20;
    const rowHeight = 20; // Adjust this based on your row height
    const headerHeight = 25; // Adjust this based on your header height
    const paginationHeight = 40; // Adjust this based on your pagination height
    const calculatedHeight = rowCount * rowHeight + headerHeight + paginationHeight;

    // Set the height dynamically
    const agGridContainer = document.getElementById("ag-grid-container");
    if (agGridContainer) {
      agGridContainer.style.height = `${calculatedHeight}px`;
    }
  }, [displayedRows]);

  return (
      <div className="ag-theme-alpine" id={tableId}>
        <div className="grid-container" style={{ overflow: "auto", height:'100%', width: '100%' }}>
        <Box sx={{p:"2.5% 1.5% 1% 1.5%",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            {/* <Typography fontSize={'1.2rem'} variant="h2">{tableTitle}({isLoading?'...':data?.total})</Typography> */}
            <Typography fontSize={'1.2rem'} variant="h2">{tableTitle}</Typography>
           {isWafAdmin && <div style={{ position: 'relative' }}>
        <FormControl variant="outlined" style={{ width: '20rem', marginBottom: '1rem' }}>
        
        <Input
          value={searchTerm}
          onChange={handleChangeSearch}
          onKeyDown={handleKeyPress}
          placeholder="Search by column"
          style={{
            fontFamily: "Barlow",
            outline: "none",
            borderRadius: '6rem', // Apply border radius
            border: '0.5px solid #ccc', // Add border
          }}
          disableUnderline
          startAdornment={
            <InputAdornment position="start">
            <Select
             disableUnderline
              value={selectedSearchColumn}
              onChange={(e) => setSelectedSearchColumn(e.target.value)}
              displayEmpty
              input={<Input />}
              sx={{fontSize:"0.9rem",color:"#4D4D4D",pl:1}}
            >
              {data?.headers?.filter((columnDef)=>!['Event Time','Severity'].includes(columnDef.headerName)).map((columnDef, index) => (
                <MenuItem key={index} value={columnDef.field}>
                  {columnDef.headerName === 'Unique ID'?'Transaction ID':columnDef.headerName}
                </MenuItem>
              ))}
            </Select>
            </InputAdornment>

          }
        />
      </FormControl>
          
            {searchTerm && (
              <IconButton
                aria-label="clear input"
                onClick={()=>{
                  setSearchTerm('')
                  setFilterValue('')
                }}
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '8px',
                  transform: 'translateY(-50%)',
                }}
              >
                <ClearIcon />
              </IconButton>
            )}
            </div>}

            <div className={classes.exportButtons}>
              {tableId ==='attacksBlockedPopUp'?'':<Button
                variant="contained"
                onClick={handleMenuOpen}
                sx={{fontSize: "0.87rem",borderRadius:4,color:"#FFFFFF"              }}
              >
                Download Logs
              </Button>}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                
              >
                <MenuItem 
                    onClick={()=>handleExportCSV(data?.rows)}
                    >Download as CSV</MenuItem>
                <MenuItem 
                  onClick={() => setPdfDialogOpen(true)}                    >
                  Download as PDF
                </MenuItem>
              </Menu>
              {isPopUp && <IconButton onClick={handleClosePopUp} ><Close color="primary"/></IconButton>}
            </div>
            
        </Box>

        {(data?.total === 0) ? (
          <NoAttacksPage title={""} />
        ) :
        isLoading?
        <div style={{height:"20rem"}}>
          <PreLoader/> 
          </div>:
          (
             <AgGridReact
                containerStyle={{
                  height: "25rem"
                }}
                rowData={data?.rows}>
                {data?.headers?.map((columnDef, index) => {

                  return (
                    (
                      <AgGridColumn
                        key={index} {...columnDef}
                        filter={true} // Enable filtering for this column
                        filterParams={{
                          filterOptions: ['contains'],
                          suppressAndOrCondition: true, // Hide the "AND" and "OR" filter options
                        }}
                        suppressFilterMenu={true} // Hide the filter dropdown menu
                        headerName={columnDef?.field === 'event_time' ? 'Event Time (IST)' :
                            columnDef?.field === 'unique_id' ? 'Transaction ID' :
                            columnDef?.headerName} // Dynamically set column name
                        valueFormatter={params => {
                          // Check if the field is 'event_time' and if the value is a valid date
                          if (columnDef?.field === 'event_time' && moment(params?.value).isValid()) {
                            // Convert the time to IST (Indian Standard Time)
                            return convertToIST(params?.value);
                          }
                          // Return the original value for other columns
                          return params?.value;
                        } }
                        tooltipComponent={({ value }) => {
                          // Check if the value is a valid date
                          if (moment(value).isValid()) {
                            // Convert the time to IST (Indian Standard Time)
                            const istTime = convertToIST(value);
                            return <span style={{ backgroundColor: "#fff", border: "1px solid grey", padding: '0.5rem' }}>{istTime}</span>;
                          }
                          // Return the original value for other columns
                          return <span style={{ backgroundColor: "#fff", border: "1px solid grey", padding: '0.5rem' }}>{value}</span>;
                        } } />
                    )
                  );
                })}



                {tableId === 'attacksBlockedPopUp' ? '' : <AgGridColumn
                  headerName="More Info"
                  cellRendererFramework={({ data }) => (
                    <IconButton
                      title="Click here for more info"
                      size="small"
                      onClick={() => showRowDetails(data)}
                      style={{ margin: 0, color: "#1FA7D3" }}
                    >
                      {/* <VisibilityIcon/> */}
                      <Button sx={{ height: "2rem" }} variant="outlined">view</Button>
                    </IconButton>
                  )}
                  minWidth={100} />}
              </AgGridReact>
              
          )}
     
      <Box sx={{
        display: "flex",
        backgroundColor:"#FFFFFF",
        justifyContent: "center",
        alignItems: "center",
        width: "100%", p: 1
      }} component={"div"}>
          <FormControl id={"paginationId"}>
            <span style={{ fontSize: "1rem",fontFamily: "Barlow", fontWeight: '100 !important' , color: '#707070'}}>Rows per page
              <Select
                sx={{ ml: 1,fontSize: "0.9rem",fontFamily: "Barlow" , color: '#707070' }}
                size="small"
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}
              >
                {[20, 40, 80, 100, -1].map((option) => (
                  <MenuItem 
                    key={option} 
                    value={option === -1?data?.total:option}
                    style={{ fontSize: "0.87rem",fontFamily: "Barlow" }}>
                    {option === -1 ? 'All' : option}
                  </MenuItem>
                ))}
              </Select>
            </span>
          </FormControl>
          <Stack sx={{
            // color: "#151515",
            borderRadius: 8,
            fontSize: '1rem',
            p: 1, ml: 2 , color: '#707070'
          }}>{startIndex + 1}-{endIndex + 1} of {data?.total}</Stack>
          <Pagination
            page={page}
            size="large"
            count={data?.totalPages}
            onChange={handleChangePage}
            // variant="outlined" 
            sx={{
              '& .Mui-selected': {
                backgroundColor: '#1FA7D3 !important', // Replace with your desired custom color for the selected page
                color: '#ffffff', // Replace with the desired text color for the selected page
                fontSize: "0.87rem", fontWeight: 500
              },
            }}
             />
        </Box>
      </div>
      <CustomDialogBox
        isPdfDialogOpen={isPdfDialogOpen}
        setPdfDialogOpen={setPdfDialogOpen}
        data={data}
        selectedHeaders={selectedHeaders}
        setSelectedHeaders={setSelectedHeaders}
        setAnchorE={setAnchorEl}
      />
    
      <MoreInfo 
        drawerOpen={drawerOpen} 
        closeDrawer={closeDrawer} 
        selectedRowData={selectedRowData} 
        selectedTabIndex={selectedTabIndex} 
        setSelectedTabIndex={setSelectedTabIndex}/>
    
        
        
    </div>
  );
};

export default AgGridTable;
