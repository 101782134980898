
import { Box, Typography } from "@mui/material";
import NoAttacks from "../../../assets/svg/noAttacks.svg"


const NoAttacksPage = ({title})=>{
    return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                width={'100%'}
                justifyContent={"center"}
                gap={"10%"}
                p={"2%"}
                borderRadius={'1%'}
                sx={{backgroundColor:"#FFFFFF"}}
                >
            <Typography 
            sx={{alignSelf:"center",marginBottom: "3%",}} variant="h2">
                {title}
            </Typography>
            
            <div style={{
               display:"flex",
               flexDirection:"column",
               alignItems:"center"
                }}>
                <img style={{width:"25%"}} src={NoAttacks} alt="no attacks"/>
                <Typography variant="body2" >No Attacks in the selected domain</Typography>
            </div>
            </Box>
        );
    
}

export default NoAttacksPage