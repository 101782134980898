import { saveAs } from 'file-saver';


const convertToCSV = (colDefs,rowData) => {
    const visibleHeaders = colDefs?.map(header => header.headerName).join(',') + '\n';
  
    const headerRows = colDefs?.map(header => header.field)
  // console.log("data?.headers ",data?.headers)
    const dataRows = rowData?.map((row) => {
      // Ensure that each value is properly quoted
      const quotedValues = headerRows.map(header => {
        
        const value = row[header];
        console.log("value ",header)
        // If the value contains a comma or double quote, wrap it in double quotes and escape any double quotes within the value
        if (/,|"|\n/.test(value)) {
          return `"${value.replace(/"/g, '""')}"`;
        }
  
        return value;
      });
  
      return quotedValues.join(',');
    }).join('\n');
  
    return visibleHeaders + dataRows;
  };


export const handleDownloadCSV = (colDefs,rowData) => {
    // Convert row data to CSV format
    const csvData = convertToCSV(colDefs,rowData);

    // Create a Blob with the CSV data
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });

    // Trigger a download using FileSaver.js
    saveAs(blob, 'exported_data.csv');
  };




  export const handleExportCSV = async (rowData) => {
    // Function to convert row data to CSV format
    const convertToCSV = (data) => {
        const rows = data.flatMap(row =>
            row.audit_logs.map(log => [
                row.client_ip || '',
                row.request_method || '',
                row.status || '',
                row.event_time || '',
                row.url || '',
                log.attack_category || '',
                log.data || '',
                log.impact || '',
                log.message || '',
                log.recommendation || '',
                row.severity || ''
                
            ].map(value => `"${(value || '').replace(/"/g, '""')}"`).join(','))
        );

        return [
            "ClientIP,RequestMethod,Status,EventTime,URL,AttackCategory,Data,Impact,Message,Recommendation,Severity",
            ...rows
        ].join('\n');
    };

    // Create a Blob object for CSV content
    const csvData = convertToCSV(rowData);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

    // Create a data URI for the Blob
    const url = URL.createObjectURL(blob);

    try {
        // Create a link element
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'logs.csv');

        // Trigger a click event on the link to start downloading the CSV file
        document.body.appendChild(link);
        link.click();
    } finally {
        // Revoke the object URL to free up memory
        URL.revokeObjectURL(url);
    }
};

