import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import './mapChart.css';
import { Box, Button, Typography } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import geoJsonData from './topo.json';
import { formatNumber } from '../../utils/formatNumber';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
// const TRAFFIC_STOP_COLOR = '#41C797';
// const TRAFFIC_START_COLOR = '#bfccbe';
// const ATTACK_START_COLOR = '#FFFF00';
// const ATTACK_STOP__COLOR = '#FF0000';

const TRAFFIC_STOP_COLOR = '#019B82';
const TRAFFIC_START_COLOR = '#bfccbe';
const ATTACK_START_COLOR = '#FFA824';
const ATTACK_STOP__COLOR = '#DA222E';

const MAP_TITLE = 'Geo Map'

const MapChart = ({ traffic, heatMapData }) => {
  const STOP_COLOR = traffic === 'blocked' ? ATTACK_STOP__COLOR : TRAFFIC_STOP_COLOR;
  const START_COLOR = traffic === 'blocked' ? ATTACK_START_COLOR : TRAFFIC_START_COLOR;

  const mapContainerRef = useRef<any>(null);
  const gradientBarRef = useRef<SVGRectElement | null>(null);
  const gradientBarTextMinRef = useRef<SVGTextElement | null>(null);
  const gradientBarTextMaxRef = useRef<SVGTextElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const countryPathsRef = useRef<any>(null);

 let zoom;
  useEffect(() => {
    const svg = d3.select(mapContainerRef?.current);
    const gradientBar = d3.select(gradientBarRef?.current);
    const gradientBarTextMin = d3.select(gradientBarTextMinRef?.current);
    const gradientBarTextMax = d3.select(gradientBarTextMaxRef?.current);
    const tooltip = d3.select(tooltipRef?.current).style('opacity', 0);

    const handleResize = () => {
      const container = svg.node()?.parentNode;
      const containerWidth = container?.clientWidth;
      const containerHeight = container?.clientHeight;

      const originalWidth = 800;
      const originalHeight = 380;

      const scaleX = containerWidth / originalWidth;
      const scaleY = containerHeight / originalHeight;
      const scale = Math.min(scaleX, scaleY);

      const newWidth = isFullscreen ? originalWidth * scale : originalWidth*scale;
      const newHeight = isFullscreen ? originalHeight * scale : originalHeight*scale;

      const projection = d3.geoMercator().fitSize([newWidth, newHeight], geoJsonData);
      const path = d3.geoPath().projection(projection);

      svg
        .attr('width', newWidth)
        .attr('height', newHeight)

      projection.fitSize([newWidth, newHeight], geoJsonData);
      svg.selectAll('path').remove();

      

      countryPathsRef.current = svg
        .selectAll('path')
        .data(geoJsonData.features)
        .enter()
        .append('path')
        .attr('d', path)
        .style('fill', d => {
          const countryCode = d.id;
          const value = heatMapData[countryCode];
          const maxValue = d3.max(Object.values(heatMapData)) || 100;
          const colorScale = d3.scaleLinear().domain([0, maxValue]).range([START_COLOR, STOP_COLOR]);

          return value ? colorScale(value) : 'lightgrey';
        })
        .style('stroke', 'white')
        .on('mouseover', (event, d) => {

          const countryCode = d.id;
          const value = heatMapData[countryCode];

      
          const tooltipContent = `<span style="color:#1FA7D3;">${d.properties.name}</span><br/><span style="color:#000;"> ${
            traffic === 'blocked' ? 'Attacks' : 'Traffic'
          }: ${value ? value : 'Null'}</span>`;

          const [x, y] = d3.pointer(event);

          tooltip.style('opacity', 0.9);
          tooltip.html(tooltipContent).style('left', `${x}px`).style('top', `${y}px`).style('color', '#3BB2D9').style('font-famlily', 'Barlow').style('font-size', '1rem');
        })
        .on('mouseout', (event, d) => {

          tooltip.style('opacity', 0);
        });

      const { gradientBarX, gradientBarY, gradientBarWidth, gradientBarHeight } = handleGradientBar(newWidth, newHeight);

      gradientBar
        .attr('x', gradientBarX)
        .attr('y', gradientBarY)
        .attr('width', gradientBarWidth)
        .attr('height', gradientBarHeight);

      const textOffset = 15;

      const isMinMaxEqual = d3.min(Object.values(heatMapData)) === d3.max(Object.values(heatMapData));
      gradientBarTextMin
        .attr('x', gradientBarX + gradientBarWidth / 2)
        .attr('y', gradientBarY - textOffset)
        .attr('text-anchor', 'middle')
        .text(isMinMaxEqual ? 0 : formatNumber(d3.min(Object.values(heatMapData))) || '')
        .on('mouseover', () => {
          gradientBarTextMin.text(d3.min(Object.values(heatMapData)) || '');
        
        })
        .on('mouseout', () => {
          gradientBarTextMin.text(isMinMaxEqual ? 0 : formatNumber(d3.min(Object.values(heatMapData))) || '');
        });

      gradientBarTextMax
        .attr('x', gradientBarX + gradientBarWidth / 2)
        .attr('y', gradientBarY + gradientBarHeight + textOffset)
        .attr('text-anchor', 'middle')
        .text(formatNumber(d3.max(Object.values(heatMapData))) || '')
        .on('mouseover', () => {
          gradientBarTextMax.text(d3.max(Object.values(heatMapData)) || '');
        })
        .on('mouseout', () => {
          gradientBarTextMax.text(isMinMaxEqual ? 0 : formatNumber(d3.max(Object.values(heatMapData))) || '');
        });

         zoom = d3.zoom().on('zoom', (event) => {
          countryPathsRef?.current?.attr('transform', event.transform);
        });

        svg.call(zoom)

    };

    handleResize();

   
    const containerResizeHandler = () => {
      handleResize();
    };

    window.addEventListener('resize', containerResizeHandler);

    return () => {
      window.removeEventListener('resize', containerResizeHandler);
    };
  }, [heatMapData, isFullscreen]);

  const handleGradientBar = (newWidth: number, newHeight: number) => {
    const gradientBarX = newWidth - 30;
    const gradientBarY = newHeight - 180;
    const gradientBarHeight = 140;
    const gradientBarWidth = 12;

    return { gradientBarX, gradientBarY, gradientBarWidth, gradientBarHeight };
  };

  useEffect(() => {
    const fullscreenChangeHandler = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', fullscreenChangeHandler);

    return () => {
      document.removeEventListener('fullscreenchange', fullscreenChangeHandler);
    };
  }, []);

  return (
    <Box sx={{position: 'relative',width: '100%',pt:"1rem",height:{xs:400,md:"100%"} ,overflowY:"auto" }}>
      <Typography variant="h2" fontSize={"1.2rem"}sx={{textAlign:{xs:"right",md:"center"},pr:{xs:2,md:0}}}>{MAP_TITLE}</Typography>
      <svg 
        ref={mapContainerRef} 
        style={{ position: 'absolute', top: '55%',left: '50%', transform: 'translate(-50%, -50%)' }}
          >
        <linearGradient id="gradientBar" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style={{ stopColor: START_COLOR }} />
          <stop offset="100%" style={{ stopColor: STOP_COLOR }} />
        </linearGradient>
        <rect ref={gradientBarRef} fill="url(#gradientBar)" />
        <text ref={gradientBarTextMinRef} className="gradient-text"></text>
        <text ref={gradientBarTextMaxRef} className="gradient-text"></text>
      </svg>
      <div
        ref={tooltipRef}
        style={{
          position: 'absolute',
          pointerEvents: 'none',
          backgroundColor: 'white',
          padding: '0.5rem',
          color: 'blue',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.9)',
          borderRadius: 4 // Apply box shadow only for "Attacks Blocked" card
        }}
      ></div>
    </Box>
  );
};

export default MapChart;



