import React from 'react';
import { makeStyles, Box, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { LoadingDots } from '../../../screen/Dashboard/components/DotsLoader';

const useStyles = makeStyles(() => ({
    preLoader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position:"relative"
    },
}));

const PreLoader = () => {
    // const { loadingData } = useSelector((state: any) => ({ loadingData: true }));

    const classes = useStyles();
    return (
        // @ts-ignore
            <Box className={classes.preLoader}> 
             <CircularProgress style={{color:"#1FA7D3"}} size={24}/>
             <Typography sx={{color:"#1FA7D3",ml:'0.5rem',fontSize:"1.2rem",fontFamily:"Barlow"}} variant='h6'>
                Loading...Please wait</Typography>
                {/* <LinearProgress variant='indeterminate' /> */} 

            </Box>
    );
    // return (
    //     // @ts-ignore
    //     loadingData ? (
    //         <Box className={classes.preLoader}> 
    //          <CircularProgress size={24}/>
    //          <Typography sx={{color:"#1FA7D3",fontSize:"1.2rem",fontFamily:"Barlow"}} variant='h6'>Loading...Please wait</Typography>
    //             {/* <LinearProgress variant='indeterminate' /> */} 

    //         </Box>
    //     ) : null
    // );
};

export default PreLoader;
