import { MENUS } from "../constants/endpoints";
import { setIsWafAdmin } from "../redux/authentication/authActions";
import { setMenusList } from "../redux/ui/uiActions";
// import { setIsWafAdmin, setMenusList } from "../redux/actions";
import { request } from "./request";


const MENUS_URL = `${process.env.REACT_APP_API_GATEWAY_URL}/${MENUS}`


export const getMenusApi = async(dispatch)=>{

    const response:any = await request.get(MENUS_URL)

    const isAllowed = response.data.findIndex((menu) => menu.url === '/clients') !== -1

    dispatch(setIsWafAdmin(isAllowed))

    dispatch(setMenusList(response.data))

    return isAllowed


}