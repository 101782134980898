// import { Box } from "@mui/material";
// import { CountryList } from "./CountryList";
// import { VerticalCard } from "./VerticalCard";

// export const GradientCards = ({cardsData,handlePopupOpen,countries}) => {
  

//   return (
//     <Box id="gradientCards" sx={{
//       width: { xs: "100%", md: "40%" },
//       display: "flex",
//       alignItems: "center",
//       flexDirection: "column",
//       gap: "1rem" // Adjust the gap value as needed
//     }}>
//       <Box sx={{ width: "100%", display: 'flex', justifyContent: "space-between" }}>
//         {cardsData.slice(0, 2).map((item, index) => (
//           <Box sx={{ width: "48%" }} key={item.id}>
//             <VerticalCard
//               key={item.id}
//               isLoading={item.isLoading}
//               number={item.number}
//               title={item.title}
//               imageUrl={item.image}
//               onClick={item.title === "Attacks Blocked" ? handlePopupOpen : undefined}
//             />
//           </Box>
//         ))}
//       </Box>

//       {cardsData.slice(2, 3).map((item, index) => (
//         <Box sx={{ width: "100%" }} key={item.id}>
//           <VerticalCard
//             key={item.id}
//             isLoading={item.isLoading}
//             number={item.number}
//             title={item.title}
//             imageUrl={item.image}
//             onClick={undefined}
//           />
//         </Box>
//       ))}

//         <CountryList countries={countries} />
//     </Box>
//   )
//   };

import { Box } from "@mui/material";
import { CountryList } from "./CountryList";
import { VerticalCard } from "./VerticalCard";

export const GradientCards = ({cardsData,handlePopupOpen,countries}) => {
  

  return (
    <Box id="gradientCards" sx={{
      width: { xs: "100%", md: "40%" },
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      gap: "1rem" // Adjust the gap value as needed
    }}>
      <Box sx={{ width: "100%", display: 'flex', justifyContent: "space-between" }}>
        {cardsData.slice(0, 3).map((item, index) => (
          <Box sx={{ width: {xs:"31%",xl:"32%"} }} key={item.id}>
            <VerticalCard
              key={item.id}
              isLoading={item.isLoading}
              number={item.number}
              title={item.title}
              imageUrl={item.image}
              onClick={item.title === "Attacks Blocked" ? handlePopupOpen : undefined}
            />
          </Box>
        ))}
      </Box>


        <CountryList countries={countries} />
    </Box>
  )
  };

