// Header.tsx
import React, { useEffect, useState } from 'react';
import './Header.css'; // Import your own CSS file for styling
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import { Box} from '@mui/material';
import MoreIcon from '@mui/icons-material/MoreVert';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../sidebar/Sidebar';
import MobileMenu from './MobileMenu';
import Logo from './Logo';
import UserProfileMenu from './UserProfileMenu';
import LogoutMenu from './LogoutMenu';
import DropdownComponent from './DropdownComponent';
import { domainsByClientApi } from '../../../screen/Dashboard/utils/apis';



const Header = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { isWafAdmin } = useSelector((state: any) =>  state.authentication );

  const dispatch = useDispatch()


  useEffect(() => {
    domainsByClientApi(dispatch);
  }, []);


  const handleMenuClick = () => {
    setIsSidebarOpen(true);
  };

  const handleSidebarClose = () => {
    setIsSidebarOpen(false);
  };

  
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  
  
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };





  return (
    <>
      <AppBar position="static"   className="app-header">
          <Box className="logo-menu-container">
            {isWafAdmin && 
            <IconButton color="primary" edge="start" onClick={handleMenuClick}>
              <MenuIcon />
            </IconButton>}
            <Logo/>
          </Box>
          <DropdownComponent/>

          <Box className="profile" sx={{display: { xs: 'none', md: 'flex' },fontFamily:'Barlow'}}>
            <UserProfileMenu handleProfileMenuOpen={handleProfileMenuOpen}/> 
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="medium"
              aria-label="show more"
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="primary"
            >
              <MoreIcon/>
            </IconButton>
          </Box>
      </AppBar>
      <Sidebar isOpen={isSidebarOpen} onClose={handleSidebarClose} />
      <MobileMenu anchorEl={mobileMoreAnchorEl} handleProfileMenuOpen={handleProfileMenuOpen} 
        handleMobileMenuClose={handleMobileMenuClose}/>
      <LogoutMenu anchorEl={anchorEl} handleMenuClose={handleMenuClose}/>
    </>
  );
};

export default Header;


