
import React, { useState } from 'react';
import { Button, Container, Grid, Input, TextField, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setCustomButton } from '../../../redux/ui/uiActions';
import { setSnackbar } from '../../../redux/notification/notificationActions';
import { Close } from '@mui/icons-material';

const getCurrentDate = () => {
  const currentDate = new Date();
  return currentDate.toISOString().split('T')[0];
};
const DateTimePicker = ({handleCustomDate}) => {

  const [fromDate, setFromDate] = useState('');
  const [fromTime, setFromTime] = useState('12:00');
  const [toDate, setToDate] = useState('');
  const [toTime, setToTime] = useState('12:00');

  const dispatch = useDispatch()


  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleFromTimeChange = (e) => {
    setFromTime(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const handleToTimeChange = (e) => {
    setToTime(e.target.value);
  };

  const formatDateTime = (date, time) => {
    return `${date} ${time}:00`;
  };

  const handleSubmit = () => {
    const formattedFromDate = formatDateTime(fromDate, fromTime);
    const formattedToDate = formatDateTime(toDate, toTime);
    if (new Date(formattedFromDate) < new Date(formattedToDate)) {

      const customDateRange = {
          from: formattedFromDate, 
          to: formattedToDate
        };

        handleCustomDate(dispatch,customDateRange)
      dispatch(setSnackbar({
        ...customDateRange,open:true
      }))
      dispatch(setCustomButton(false))

        
    } else {
      dispatch(setCustomButton(true))
      handleCustomDate(dispatch,false)
    }
   
  };
    
    return (

  <Container maxWidth="xs" sx={{padding:2}}>
      <Grid container spacing={2}>
        <Grid sx={{display:"flex",alignItems:"center",justifyContent:"space-between"}} item xs={12}>
          <Typography variant="h2">From</Typography>
          <Button sx={{color:"#1FA7D3",borderRadius:4}}
            onClick={()=>dispatch(setCustomButton(false))}
            >
              <Close/>
          </Button>
        </Grid>
        
        <Grid item xs={6}>
          <TextField
            size='small'
            sx={{
              '.MuiInputBase-root':{
                borderRadius:6,
              }
            }}
            label=""
            type="date"
            value={fromDate}
            onChange={handleFromDateChange}
            fullWidth
            inputProps={{
              max: getCurrentDate(), // Function to get the maximum allowed date
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField size='small'
            sx={{
              '.MuiInputBase-root':{
                borderRadius:6
              }
            }}
            label=""
            type="time"
            value={fromTime}
            onChange={handleFromTimeChange}
          
          />
        </Grid>
        <Grid item xs={7}>
          <Typography variant="h2">To</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField size='small'

            sx={{
              '.MuiInputBase-root':{
                borderRadius:6
              }
            }}
            label=""
            type="date"
            value={toDate}
            onChange={handleToDateChange}
            fullWidth
            inputProps={{
              min:fromDate,
              max: getCurrentDate(), // Function to get the maximum allowed date
            }}
            
          />
        </Grid>
        <Grid item xs={2}>
          <TextField size='small'

            sx={{
              '.MuiInputBase-root':{
                borderRadius:6
              }
            }}
            label=""
            type="time"
            value={toTime}
            onChange={handleToTimeChange}
          />
        </Grid>
        <Grid item xs={12} sx={{display:"flex",justifyContent:"space-between"}}>
          <Button sx={{borderRadius:4,color:"#FFFFFF"}} variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
         
        </Grid>
      </Grid>
    </Container>

    )
  };
  
  export default DateTimePicker;