import {
  TEMPLATE_UPLOAD,
  TEMPLATE_DOWNLOAD,
  DELETE_DOCUMENT
} from "../constants/endpoints";
import { ResponseProps } from "./request";
import { request } from "./request";
import { MetaInfo } from "../types/types";

export const TEMPLATE_UPLOAD_ENDPOINT = `${process.env.REACT_APP_API_GATEWAY_URL}${TEMPLATE_UPLOAD}${process.env.REACT_APP_DOC_ID}`;
export const TEMPLATE_DOWNLOAD_ENDPOINNT = `${process.env.REACT_APP_API_GATEWAY_URL}${TEMPLATE_DOWNLOAD}`;


export const uploadFileApi = async (
  fileName: string,
  file: File,
  documentPath: string,
  meta?: MetaInfo
): Promise<{ success: boolean; message?: string; data?: any }> => {
  const blob = new Blob([file]);
  const fileOfBlob = new File([blob], `${fileName}`);
  const params = {
    documentName: fileName,
    file: fileOfBlob,
    documentPath: documentPath,
    documentDescription: "",
    metaInfo: JSON.stringify(meta),
  };


  const r: ResponseProps = (await request.postForm(
    `${TEMPLATE_UPLOAD_ENDPOINT}`,
    params
  )) as ResponseProps;
  if (r && r.success) {
    return { success: true, data: r.data, message: r.message };
  }
  return { success: false, data: {}, message: "" };
};


export const downloadFile = async (id: string, fileName: string) => {
  let Token = sessionStorage.getItem("react-token");
  fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/dms/v1/documents/download?id=${id}`,
    {
      method: "get",
      headers: new Headers({
        Authorization: `Bearer ${Token}`,
      }),
    }
  )
    .then((res) => res.blob())
    .then((blobData) => {
      const fileURL = URL.createObjectURL(blobData);
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(fileURL);
    });
};

export const downloadTemplateApi = async (
  id: string
): Promise<{ success: boolean; message?: string; blobFile?: any }> => {
  const response = (await request.getBlob(
    `${TEMPLATE_DOWNLOAD_ENDPOINNT}/${id}`
  )) as ResponseProps;

  if (response && response.success) {
    return { success: true, blobFile: response.data };
  }

  return { success: false };
};
// export default {};

export const deleteDocument = async (id) => {
  const apiEndpoint = `${process.env.REACT_APP_API_GATEWAY_URL}${DELETE_DOCUMENT}${id}`;
  const r: ResponseProps = (await request.delete(apiEndpoint)) as ResponseProps;

  if (r.success) {
    return { success: r.success, message: r.message, data: r.data };
  }

  return { success: false };
};
