import React, { useEffect, useState } from 'react';
import { MenuItem, Select } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { setDomain } from '../../../redux/domainData/domainActions';

const DropdownComponent = () => {
  const [selectedOption, setSelectedOption] = useState<any>({});
  const removeDropdown = ['/clients', '/users'].some((path) => window.location.pathname.includes(path));
  const theme = useTheme();
  const dispatch = useDispatch();
  const { domainsByClient, domain } = useSelector((state: any) => state.domainData);
  
  useEffect(() => {
    // Retrieve stored item from sessionStorage
    const storedItem = JSON.parse(sessionStorage.getItem('domain') || '{}');
    
    // If stored item is not empty, set selectedOption state
    if (Object.keys(storedItem).length !== 0) {
      setSelectedOption(storedItem);
    } else {
      // If stored item is empty, set selectedOption state to the default domain
      setSelectedOption(domain);
    }
  }, [domain]);

  
  const handleDropdownChange = (event: any) => {
    const selectedDomain = event.target.value;
    sessionStorage.setItem("domain", JSON.stringify(selectedDomain));
    setSelectedOption(selectedDomain);
    dispatch(setDomain(selectedDomain))
  };

   
  if (removeDropdown) {
    return null;
  }

  return (
    <div className="dropdown">
      <Select
        native={false}
        size="small"
        value={selectedOption}
        renderValue={(selectedItem)=>{
          
          return selectedItem.domain_name
        }}
        onChange={handleDropdownChange}
        sx={{
          borderRadius: 8,
          width: { xs: '70%', md: '70%' },
          backgroundColor: 'white',
          boxShadow: '0px 1.5px 2px rgba(0, 0, 0, 0.3)',
          fontSize:"1rem",color:"#4D4E4E",
          [theme.breakpoints.up('md')]: {
            size: 'medium',
          },
          [theme.breakpoints.up('lg')]: {
            size: 'large',
          },
        }}
      >
        {domainsByClient?.map((item, index) => (
          <MenuItem
            key={index}
            value={item} // Ensure that value is the entire item object
            sx={{
              fontSize: '1rem',
              fontWeight: 500,color:"#4D4E4E"
            }}
          >
            {item.domain_name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default DropdownComponent;
