import * as React from 'react';

import { Box, IconButton, Modal, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import CreateClient from './CreateClient';
import { ConnectingAirportsOutlined, Delete,Edit } from '@mui/icons-material';
import { addOrEditOrDeleteClient, getAllClientUrls, getAllPaginatedClients } from './clientServices';
import EditableChip from './EditableChip';
import CONSTANTS from '../../../constants/constants';
import { useDispatch } from 'react-redux';
import UrlAddIcon from '../../../assets/svg/link-2.svg'

import UrlTooltip from './UrlTooltip';
import EditClient from './EditClient';
import { TEMPLATE_UPLOAD } from '../../../constants/endpoints';
import { uploadFileApi } from '../../../services/UploadFile';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { CustomButton, CustomDiv, CustomTableCell, CustomTableContainer, CustomTableHead, HeadingStyled, StyledImg, StyledMuiIcon } from '../reuseStyles';
import { CustomTablePagination, LightTooltip } from '../adminStyles';
import SendIcon from '@mui/icons-material/Send';
import SendReport from './SendReport';
import LoadSpinner from '../../../components/common/LoadSpinner';
import { pushNotification } from '../../../redux/notification/notificationActions';

const headers =[
    // {id:"client-id",label:"Client ID",padding:""},
    {id:"client-name",label:"Client Name",padding:""},
    {id:"business-owner",label:"Business Owner",padding:""},
    {id:"bo-email",label:"B.O Email",padding:""},
    {id:"bo-phone",label:"B.O Phone",padding:""},
    {id:"technical-owner",label:"Technical Owner",padding:""},
    {id:"to-email",label:"T.O Email",padding:""},
    {id:"to-phone",label:"T.O Phone",padding:""},
    {id:"recommedations",label:"Recommedations",padding:""},
    {id:"actions",label:"Actions",padding:6}
    
]


const useStyles = makeStyles((theme)=>({
  tmTableHeading:{
      marginTop:"10px",
     
  },
  ellipsisCell: {
    
    maxWidth: '100px', // Set your desired maximum width
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    // borderRight:"1px solid lightblue"
  },
}))

const AllClientsList = ()=>{


const [clientsList,setClientsList] = useState<any[]>([])
const [domains,setDomains] = useState<any[]>([])
const [searchTerm,setSearchTerm] = useState<string>("")
const [clientId,setClientId] = useState<string>("")
const [isExpanded, setIsExpanded] = useState(false);
const [isCreateClient,setIsCreateClient] = useState(false)
const [isEditClient,setIsEditClient]= useState(false)
const [selectedClient,setSelectedClient] = useState<string>("")
const [message,setMessage] = useState("")
const [page, setPage] = React.useState<number>(0);
const [rowsPerPage, setRowsPerPage] = useState(10);
const [totalRecords, setTotalRecords] = useState(0);
const [sendReportTo,setSendReportTo] = useState("")
const [loadingUrls,setLoadingUrls] = useState(false)
const [selectMode,setSelectMode] = useState(false)

const dispatch = useDispatch()
  
  useEffect(()=>{
      handleGetPaginatedClients()
  },[searchTerm,page,rowsPerPage])
 
  const handleSearch = (event)=>{
    setSearchTerm(event.target.value)
  }

  const handleGetPaginatedClients = async()=>{
    const res:any = await getAllPaginatedClients(page+1,rowsPerPage,searchTerm)
    const clientList = res?.data?.map(item=>{
      return ({
        bo_email:item.bo_email?item.bo_email:"NA",
        bo_phone:item.bo_phone?item.bo_phone:"NA",
        business_owner:item.business_owner?item.business_owner:"NA",
        to_email:item.to_email?item.to_email:"NA",
        to_phone:item.to_phone?item.to_phone:"NA",
        technical_owner:item.technical_owner?item.technical_owner:"NA",
        clientAccId:parseInt(item.clientAccId),
        file_name:item.file_name,
        client_id:item.client_id,
        client_name:item.client_name,
      })
     
    })

    setClientsList(clientList.sort((a,b)=>b.clientAccId-a.clientAccId))
    setMessage(res?.message)
    setTotalRecords(res?.total)
  }


  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    // console.log("new page",newPage,typeof(newPage))
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


const handleGetAllurls = async()=>{
  
  // console.log("clientid",clientId)
  setLoadingUrls(true)
  const res:any = await getAllClientUrls(clientId)
  setLoadingUrls(false)
  setDomains(res)
  
  // console.log("response",res)
  // setDomains(res.sort((a,b)=>a.id > b.id ? -1:1))
}

useEffect(()=>{
  if(clientId){
    handleGetAllurls()
  }
},[clientId])

const ExpandableTableRow = ({ rowData,rowId,children, expandComponent, ...otherProps }) => {

  const handleExpand = (rowId)=>{
    setIsExpanded(true)
    setClientId(rowId)
  }

  const handleCloseExpand = ()=>{
    setDomains([])
    setClientId("")
    setIsExpanded(false)
  }

  const handleDeleteClient = async()=>{

    const response:any = await addOrEditOrDeleteClient("delete",rowData.client_id,null)
    
    if (response?.success) {
      dispatch(
      pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.SUCCESS,
      })
      );
      handleGetPaginatedClients()
    } else {
      
      dispatch(
      pushNotification({
          isOpen: true,
          message: response.error,
          type: CONSTANTS.ERROR,
      })
      );
    }
  
  }

  const handleEditClient = () =>{
    setIsEditClient(true)
    setSelectedClient(rowData)
  }

  const StyledDeleteIcon = StyledMuiIcon(Delete);
  const StyledEditIcon = StyledMuiIcon(Edit);


  return (
    <>
      <TableRow 
         {...otherProps} >
        {children}
        <TableCell 
            
           sx={{display:"flex"}}>

          {/* <LightTooltip title="file upload">
            <>
            <input
              type="file"
              id="fileInput"
              // style={{ display: 'none' }}
              onChange={(e)=>handleFileChange(e,rowData)}
            /> 
            <label style={{display:"flex",justifyContent:"center",alignItems:"center"}} htmlFor="fileInput">
                <FileUploadIcon color='primary'/>
              </label> 
                </>
          </LightTooltip>
          */}
          
          <IconButton title="Delete"
               onClick={handleDeleteClient}>
              <StyledDeleteIcon sx={{color:"red"}}/>
          </IconButton>
        
            <IconButton title="Edit" onClick={handleEditClient}>
              <StyledEditIcon sx={{color:"blue"}}/>
            </IconButton>
          
        {(!isExpanded&& rowData.client_id ===rowId )? 
            <IconButton title="Add Urls"
              onClick={()=>handleExpand(rowId)} 
            >
             <StyledImg src={UrlAddIcon}/>
            </IconButton>
         :
            <IconButton title="Close Urls Window"
              onClick={handleCloseExpand} 
            >
             <StyledImg src={UrlAddIcon}/>
            </IconButton>
         
         }
            <IconButton title="Send Report" onClick={()=>setSendReportTo(rowId)}>
              <SendIcon sx={{color:"blue"}}/>
            </IconButton>
          
          {/* {isExpanded && (rowData.client_id === clientId) && <IconButton onClick={handleCloseExpand}><Close color="primary"/></IconButton>} */}
      </TableCell> 
      </TableRow>
      {isExpanded && (rowData?.client_id === clientId) && (
        <TableRow>
        <TableCell colSpan={10}>
          {expandComponent}
        </TableCell>
        </TableRow>
      )}
    </>
  );
};


const renderCollapseDetails = (row)=>{
  
  return (
    <Box 
       gap={1}
       sx={{display:"flex",
        flexWrap:"wrap",
        justifyContent:"flex-end",
        alignItems:"center",
       }}>
  
    {/* <IconButton onClick={handleClickAddCircle}><AddCircleOutlineIcon/></IconButton> */}
   
    {loadingUrls? <LoadSpinner/>: domains && domains?.map((item: any,index:number) => {
        return <EditableChip
                  key={`$domain-${index}`}
                  domain={item.domain_name}
                  domainId={item.clientDomainId}
                  handleGetAllurls={handleGetAllurls}
                  modeValue = {item.mode}
                  selectMode={selectMode}
                  setSelectMode={setSelectMode}
               />;
    })}
      {!loadingUrls && domains?.length === 0 && <p>No Urls Added</p>}
      <UrlTooltip 
        clientId={clientId}
        handleGetAllurls={handleGetAllurls}
     />

    </Box>
  )
}

const renderTable = ()=>{

  const handleFile = async(event,row) =>{
    const file = event.target.files[0]

    const fileResponse = await uploadFileApi(file.name,file,"cyberwasp/recommendations",{})

    // console.log("file message ",fileResponse)
    if (fileResponse?.success) {
      dispatch(
      pushNotification({
          isOpen: true,
          message: fileResponse.message,
          type: CONSTANTS.SUCCESS,
      })
      );
    } else {
      
      dispatch(
      pushNotification({
          isOpen: true,
          message: "Upload file failed",
          type: CONSTANTS.ERROR,
      })
      );
    }
    // console.log("file upload ",fileResponse)

    // console.log("row ",row)
    
    const dataWithFile = {recommendations:fileResponse?.data?.id,file_name:file.name}
    const response = await addOrEditOrDeleteClient("update",row.client_id,dataWithFile)
    // console.log("response ",response)
    if (response?.success) {
      dispatch(
      pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.SUCCESS,
      })
      );
      handleGetPaginatedClients()
    } else {
      
      dispatch(
      pushNotification({
          isOpen: true,
          message: "Only json files are allowed.Please check the file extension",
          type: CONSTANTS.ERROR,
      })
      );
    }

  }
  
  return(
    <Box>
      <CustomTableContainer 
        sx={{
          width: "100%",overflow:"auto"
          
        }}>
        <Table sx={{ width: "100%"}} stickyHeader>
          <TableHead>
              <TableRow hover>
                {headers.map((header:any)=>{
                  return <CustomTableHead 
                  sx={{
                    textAlign:header.align,
                    paddingLeft:header.padding,
                    fontWeight:600,
                    fontStyle:"normal",
                    width:"40%"
                  }}key={header.id}>{header.label}</CustomTableHead>
                
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {clientsList && clientsList?.map((row)=>{
                return(
                  <ExpandableTableRow
                    hover
                    rowData={row}
                    key={row.client_id}
                    rowId={row.client_id}
                    expandComponent={renderCollapseDetails(row)}
                  >
                    {/* <CustomTableCell>
                      <LightTooltip title={row.client_id}>
                          {row.client_id}
                      </LightTooltip> 
                     </CustomTableCell> */}
                      <CustomTableCell title={row.client_name}>
                          {row.client_name}
                      </CustomTableCell>
                      <CustomTableCell title={row.business_owner}>
                          {row.business_owner}
                      </CustomTableCell>
                      <CustomTableCell title={row.bo_email}>
                          {row.bo_email}
                      </CustomTableCell>
                      <CustomTableCell title={row.bo_phone}>
                          {row.bo_phone}
                      </CustomTableCell>
                      <CustomTableCell title={row.technical_owner}>
                          {row.technical_owner}
                      </CustomTableCell>
                      <CustomTableCell title={row.to_email}>
                          {row.to_email}
                      </CustomTableCell>
                      <CustomTableCell title={row.to_phone}>
                          {row.to_phone}
                      </CustomTableCell>
                    <CustomTableCell align="center">
                     
                     <input
                        type="file"
                        id="fileInput"
                        // style={{ display: 'none' }}  //using this is misplacing the file
                        onChange={(event)=>handleFile(event,row)}
                      /> 
                      <label style={{display:"flex",justifyContent:"center",alignItems:"center"}} htmlFor="fileInput">
                        {/* <FileUploadIcon color='primary'/> */}
                        {row.file_name && <Stack fontSize={"0.8rem"}>{row.file_name}</Stack> }
                      </label> 
                      </CustomTableCell>
              </ExpandableTableRow>
                )
              })}

            {message === "" && clientsList?.length === 0 && 
              <TableRow 
              >
                <CustomTableCell>Please wait...</CustomTableCell>
              </TableRow>}

              {message !== "" && 
              <TableRow 
              >
                <TableCell>No Clients Found</TableCell>
              </TableRow>}

            </TableBody>
        </Table>
      </CustomTableContainer>
      <Paper sx={{width:"100%",flexGrow:1,mt:"0.5%",pb:0,display:"flex",justifyContent:"flex-end",alignItems:"flex-end"}}>
        <CustomTablePagination
          count={totalRecords}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
        />
    </Paper>
    </Box>
      
  )
  
}
return (
  <Box sx={{p:"4%",overflow:"auto", height:`calc(100vh - 50px)`

}} width={"100%"} >
    <Modal
      open={sendReportTo?true:false}
      onClose={() => {
        setSendReportTo("");
      }}
    >
        <Box 
      sx={{
        overflow:"auto",
        position:"absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor:"#FFFFFF",
        
      }}>
      <SendReport clientId={sendReportTo} setSendReportTo={setSendReportTo}/>
      </Box>
    </Modal>
    <Modal
        open={isCreateClient}
        onClose={() => {
          setIsCreateClient(false);
        }}
      >
        <Box 
         sx={{
            position:"absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor:"#FFFFFF",
          
         }}
        >
           
          <CreateClient 
              setShow={setIsCreateClient} 
              handleGetAllClients={handleGetPaginatedClients}/>
            
        </Box>
    </Modal>
    <Modal
        open={isEditClient}
        onClose={() => {
          setIsEditClient(false);
        }}
      >
      <Box 
        sx={{
        
          position:"absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor:"#FFFFFF",
         
        }}
        className="createTaskModalContainerTm">
          <EditClient 
            selectedClient={selectedClient} 
            setShow={setIsEditClient}
            handleGetAllClients={handleGetPaginatedClients}/>
        </Box>
    </Modal>
      <HeadingStyled color="rgb(5, 136, 211)" variant='h4'>Clients</HeadingStyled>
      <Box display={"flex"} flexDirection={"row"} mt={"1rem"} mb={"2rem"}>
        <TextField
            type={"search"}
            value={searchTerm}
            placeholder={"search"}
            sx={{ 
              width:"18%",
              minWidth:"10em",
              justifyContent: "flex-start",
              ".MuiInputBase-input":{
                 height:{lg:"1.5rem",xl:"1.8rem"}
              }
             }}
              size={"small"}
            onChange={handleSearch}
        />
      
        <CustomButton
          // className="create-button"
          sx={{ 
            lineHeight:"100%",
            justifyContent: "flex-end",ml:"auto",
          }}
          variant={"contained"}
          onClick={() => setIsCreateClient(true)}
          >
          Add Client
        </CustomButton>
    </Box>


    {renderTable()}

  </Box>
)

}

export default AllClientsList