import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Chip, TextField, IconButton } from '@mui/material';
import { Save, Cancel, Delete } from '@mui/icons-material';
import { deleteUrlFromClient, editUrlInClient } from './clientServices';
import CONSTANTS from '../../../constants/constants';
import { useDispatch } from 'react-redux';
import { StyledMuiIcon } from '../reuseStyles';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import { pushNotification } from '../../../redux/notification/notificationActions';


interface Props{
    domain:string;
    domainId:number;
    handleGetAllurls:any;
    modeValue?:string;
    selectMode?:any;
    setSelectMode?:any;
}



const EditableChip = ({domain,domainId,handleGetAllurls,modeValue,selectMode,setSelectMode}:Props) => {
  const [editMode, setEditMode] = useState(false);
  const [chipValue, setChipValue] = useState('Editable Chip');
  const [tempValue, setTempValue] = useState('');
  // const [selectMode,setSelectMode] = useState(false)
  const dispatch = useDispatch()

  const handleChipClick = () => {
    setTempValue(domain);
    setEditMode(true);
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    // Regular expression to check for a valid website URL pattern

      setTempValue(inputValue);
      
  };


  const handleToggle = async() => {
    const domainMode = selectMode?"blocking":"detectionOnly"

    // console.log("select mode ",selectMode)
    // console.log("domainmode",domainMode)

    // console.log("domainId,selectMode: domainmode,mode",domainId,selectMode,domainMode,modeValue) 
     
    const response:any = await editUrlInClient(domainId,{"mode":domainMode},dispatch)

      if (response?.success) {
        dispatch(
        pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.SUCCESS,
        })
        );
        handleGetAllurls()
    } else {
        
        dispatch(
        pushNotification({
            isOpen: true,
            message: response.error,
            type: CONSTANTS.ERROR,
        })
        );
    }

  }


  const handleAvatarClick = (e) => {

    e.stopPropagation(); // Prevent the Chip click event from triggering
    setSelectMode(!selectMode)
    handleToggle(); // Toggle the selected state when the avatar is clicked
  };

  const handleSaveClick = async() => {
    setChipValue(tempValue);
    setEditMode(false);


    const response:any = await editUrlInClient(domainId,{domain_name:tempValue},dispatch)

    if (response?.success) {
      dispatch(
      pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.SUCCESS,
      })
      );
      handleGetAllurls()
  } else {
      
      dispatch(
      pushNotification({
          isOpen: true,
          message: response.error,
          type: CONSTANTS.ERROR,
      })
      );
  }
    
  };

  const handleCancelClick = () => {
    setEditMode(false);
  };

  const handleDeleteClick = async() => {


    const response:any = await deleteUrlFromClient(domainId)

    if (response?.success) {
      dispatch(
      pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.SUCCESS,
      })
      );
      handleGetAllurls()
  } else {
      
      dispatch(
      pushNotification({
          isOpen: true,
          message: response.error,
          type: CONSTANTS.ERROR,
      })
      );
  }

  };

const SaveIcon = StyledMuiIcon(Save)
const CancelIcon = StyledMuiIcon(Cancel)
const toggleIcon = modeValue == "blocking" ? <ToggleOnIcon color='primary'/> : <ToggleOffIcon />;

  return (
    <div>
      {editMode ? (
        <div>
          <TextField
            value={tempValue}
            onChange={handleInputChange}
            autoFocus
          />
          <IconButton onClick={handleSaveClick}>
            <SaveIcon sx={{color:"green !important"}} />
          </IconButton>
          <IconButton onClick={handleCancelClick}>
            <CancelIcon/>
          </IconButton>
        </div>
      ) : (
        <Chip
          sx={{
            width:"100%",
            display:"flex",
            justifyContent:"space-between",
            mr:"2rem",
            p:{xs:"0.7rem",lg:"0.9rem",xl:"1.1rem"},
            backgroundColor:"lightblue",
            '& .MuiChip-label':{
              fontSize:{xs:"0.7rem",lg:"0.9rem",xl:"1.1rem"}
            }
          }}
          label={domain}
          onClick={handleChipClick}
          variant="outlined"
          onDelete={handleDeleteClick}
          deleteIcon={<Delete sx={{color:"gray !important",fontSize:"1.4rem !important"}} />}
          // avatar={<div onClick={handleAvatarClick}>{toggleIcon}</div>}
        />
        
      )}
    </div>
  );
};

export default EditableChip;
