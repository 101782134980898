import * as d3 from 'd3';
import React, { useEffect, useRef } from 'react';
import Typography from '@mui/material/Typography';
import { Box, LinearProgress } from '@mui/material';


const SeverityChart = ({ data }) => {

  const total = data.reduce((acc, { count }) => acc + count, 0);

    // Define the order in which labels should appear
  const order = { 'High': 1, 'Medium': 2, 'Low': 3 };
  // Sort the data array based on the order defined above
  data.sort((a, b) => order[a.label] - order[b.label]);


  return (
    <Box sx={{ width: { xs: "100%", lg: "49%" }, height: "100%", borderRadius: "0.5rem", backgroundColor: "#FFFFFF", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Typography sx={{ pt: 2, pb: 2, fontSize: "1.2rem" }} variant="h2">Attacks By Severity</Typography>

    {data.map((item, index) => {
        const percentage:any = ((item.count / total) * 100).toFixed(2);

      return (
      <Box sx={{ width: "100%" }} key={index}>
        <Box sx={{ p: '1.5rem 4rem', display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography variant="h2">{item.label}</Typography>
          <div style={{ position: 'relative', width: '70%' }}>
            <LinearProgress sx={{height:40}} variant="determinate" value={percentage} />
            <Typography
              variant="body2"
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: 'black', // Customize the color if needed
              }}
            >
              {percentage}%
            </Typography>
          </div>
        </Box>
      </Box>
    )})}
  </Box>
  //   <Box sx={{width: { xs: "100%", lg: "49%" }, height: "100%", borderRadius: "0.5rem", backgroundColor: "#FFFFFF", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  //   <Typography sx={{pt:2,pb:2,fontSize:"1.2rem"}} variant="h2">Attacks By Severity</Typography>

  //   {chartdata.map((item, index) => (
  //     <Box sx={{width:"100%"}} key={index}>
  //       <Box sx={{p:'2rem 4rem 2rem 4rem',display:"flex",alignItems:"center",justifyContent:"space-between"}}>
  //         <Typography variant="h2">{item.label}</Typography>
  //         <LinearProgress sx={{ height: 40,width:"50%" }} variant="determinate" value={(item.count / 617) * 100} />
      
  //       </Box>
        
  //     </Box>
     
  //   ))}
  // </Box>
  );

  // return (
  //   <Box sx={{width: {xs:"100%",lg:"49%"},height:"100%",borderRadius:"0.5rem",backgroundColor:"#FFFFFF",display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  //     <Typography sx={{pt:2,pb:2,fontSize:"1.2rem"}} variant="h2">Attacks By Severity</Typography>
  //     {data.map(({ count, label }) => {
  //       const percentage = ((count / total) * 100).toFixed(2);
  //       return (
  //         <Box key={label} sx={{ pb:'0.5rem',height:"100%",width:"100%",display: 'flex', justifyContent:"space-between",pl:'7rem',pr:'5rem',alignItems: 'center' }}>
  //           <Typography variant="h2">{label}</Typography>
  //           <GaugeChart value={percentage} width={140} height={100} color="blue" />
  //         </Box>
  //       );
  //     })}
  //   </Box>
  // );
};

export default SeverityChart;
