import { Box, Popover, Popper, Typography } from '@mui/material';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setCustomButton } from '../../../redux/ui/uiActions';
import AgGridTable from '../agGridTable/AgGridTable';
import ButtonGroupComponent from '../components/ButtonGroupComponent'
import CardsComponent from '../components/cards/CardsComponent';
import BarChart from '../components/charts/BarChart';
import SeverityChart from '../components/charts/SeverityChart';
import Pie from '../components/charts/Pie';
import TimeLineChart from '../components/charts/TimeLine';
import DateTimePicker from '../components/CustomDate';
import ModeSwitch from '../components/ModeSwitch';
import NoAttacksPage from '../components/NoAttacksPage';
import PopupComponent from '../components/PopUpComponent';
import SnackbarComponent from '../components/SnackbarComponent';
import MyGrid from '../GridTable';
import MyComponent from '../Test';
import { fetchAttackInfoTable, fetchAttackTypesByCategoryApi, fetchAttackTypesBySeverityApi, timeLineChartApi } from '../utils/apis';
import { handleCustomDate } from '../utils/handlers';
import './mainContentstyles.css'
import CustomPie from '../components/charts/Pie';
import CustomPieChart from '../components/charts/Pie';




const MainContent = () => {

    const [chartPopUp,setChartPopUp] = useState({
      page:1,
      rowsPerPage:20,
      id:'',
      isOpen:false,label:""
    })

    const [page, setPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(20);
    const [filterValue, setFilterValue] = useState('');
    const [selectedSearchColumn, setSelectedSearchColumn] = useState('unique_id'); // Initial value is the first column
    
    const [lastUpdated, setLastUpdated] = useState<string>('');

    // variables in redux-store
    const { buttonLabel,startAndEndDate,customButton,timeLineData,attacksByCategory,attacksBySeverity,chartTableInfo,attacksInformation} = useSelector((state: any) => state.ui);
    const { domain } = useSelector((state: any) => state.domainData);
    const {isLoadingLineChart,loadingData} = useSelector((state: any) => state.loaders);
    const dispatch = useDispatch()

    const timeLabel = sessionStorage.getItem('buttonLabel')?? buttonLabel;
    const storedDomain = JSON.parse(sessionStorage.getItem('domain') || '{}');

    const domainObj = Object.keys(storedDomain).length !== 0?storedDomain:domain

    const [label,diff,format ]= timeLabel.split('-')   

    const hasSeverityData = attacksBySeverity.some(item => item.count !== 0);

    let endDateTime = label.includes('Custom') ?startAndEndDate.to:moment().format('YYYY-MM-DD HH:mm:ss')
    let startDate = moment().add(`${-(diff)}`,`${format as moment.DurationInputArg2}`).format('YYYY-MM-DD HH:mm:ss')
   
    let startDateTime = label.includes('Custom')?startAndEndDate.from:startDate
    
    const date1 = moment(endDateTime)
    const date2 = moment(startDateTime)
    const timeDifferenceInSeconds = date1.diff(date2, 'seconds')
    const params = `?start_date=${startDateTime}&end_date=${endDateTime}&domain=${domainObj.domain_name}`
  
    const timeFormatKey = useCallback((label)=>{
     
      switch (label) {
        case '12H':
          return 'minute'
        case '1W':
          return 'day'
        case '1M':
          return 'week'
        case '1Y':
          return 'month'
        case label.includes('Custom'):
          return 'day'
        default:
          return 'minute'
       }
  
  
    },[timeLabel])

    useEffect(() => {
      const updateLastUpdated = () => {
        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleString();
        setLastUpdated(formattedDate);
      };
  
      // Initial setup
      updateLastUpdated();

      // Event listener for page refresh
      window.addEventListener('beforeunload', updateLastUpdated);
  
      return () => {
        // Clean up event listener
        window.removeEventListener('beforeunload', updateLastUpdated);
      };
    }, []);
  

  
    useEffect(()=>{
      if(domainObj.domain_name!==undefined && timeDifferenceInSeconds > 1){
          timeLineChartApi(dispatch,params,timeFormatKey(label),label)
          fetchAttackTypesByCategoryApi(dispatch,params)
          fetchAttackTypesBySeverityApi(dispatch,params)
      }
    },[domainObj?.domain_name,startAndEndDate,timeLabel])

    useEffect(()=>{
      if(domainObj?.domain_name!==undefined && timeDifferenceInSeconds > 1){
        fetchAttackInfoTable(dispatch,params,page,rowsPerPage,selectedSearchColumn,filterValue,false)
      }
    },[domainObj?.domain_name,startAndEndDate,filterValue,timeLabel,page,rowsPerPage])
  
  
    useEffect(()=>{
      if(chartPopUp.label && timeDifferenceInSeconds > 1){
        fetchAttackInfoTable(dispatch,
          params,
          chartPopUp.page,chartPopUp.rowsPerPage,chartPopUp.id,chartPopUp.label,chartPopUp.isOpen)
      }
    },[chartPopUp])

    const handleClickPieSlice = (label,id)=>{
      setChartPopUp({
        page:1,
        rowsPerPage:20,
        id,
        label,
        isOpen:true
      })
    } 

    const handleChangeChartPage = (event,newPage) =>{
      setChartPopUp({
        ...chartPopUp,page:newPage
      })
    }
    const handleChangeChartRowsPerPage = (event) =>{
      setChartPopUp({
        ...chartPopUp,
        rowsPerPage:parseInt(event.target.value, 10),
        page:1
      })
    }
    const handleClosePopUp = () =>{
      setChartPopUp({
        page:1,
        rowsPerPage:20,
        id:'',
        isOpen:false,label:""
      })}
    
    const handleChangePage = (event, newPage) => setPage(newPage);
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(1);
    };

  return (
    <Box sx={{gap:"1rem",display:"flex",flexDirection:"column",alignItems:"center"}} className="app-main" >
        <Box 
            sx={{
              mt:"1rem",
              display:"flex",justifyContent:"space-between",
              alignItems:"center",width:"95%"}}>
            <ButtonGroupComponent timeLabel={timeLabel}/>
            <span style={{fontSize: "0.9rem",color:'#696969',fontWeight: 400, fontFamily: "Barlow"}}>
            Last Updated: {lastUpdated}
            </span>
            <ModeSwitch id={domainObj.id} checkedValue={domainObj.mode}/>
        </Box>
      
        <Box className="content-box" >
          <Box>
            <CardsComponent 
                setFilterValue={setFilterValue} 
                params={params} startAndEndDate={startAndEndDate} 
                domainName={domainObj.domain_name} 
                timeLabel={timeLabel} timeDifference={timeDifferenceInSeconds}/>
          </Box>
          {(timeLineData?.success)?
          <BarChart format={label} data={timeLineData?.data} total={timeLineData?.total_count}  isLoading={isLoadingLineChart}/>
          : <NoAttacksPage title={"Attacks Trendline"} />}
          <Box sx={{ display:"flex",gap:"2rem",justifyContent:"space-between",flexDirection:{xs:'column',lg:"row"},alignItems:"center",width:"100%"}}>
              {(attacksByCategory.length === 0)?
              <Box sx={{width:{xs:"100%",lg:"49%"}}}>
                <NoAttacksPage title={"Attacks By Category"}/></Box>:
                <CustomPie 
                  handleClickPieSlice={handleClickPieSlice}
                  title={"Attacks By Category"} 
                  id="attack_category" 
                  dataset={attacksByCategory}
                  />
                  }
              {!hasSeverityData?
                <Box sx={{width:{xs:"100%",lg:"49%"}}}>
                  <NoAttacksPage title={"Attacks By Severity"}/></Box>:
                    <SeverityChart data={attacksBySeverity} />
               }
          </Box>

          {(attacksInformation?.success)?
             <AgGridTable 
                setFilterValue={setFilterValue}
                handleClosePopUp={undefined}
                setSelectedSearchColumn ={setSelectedSearchColumn}
                selectedSearchColumn={selectedSearchColumn}
                tableId={"attackInfoTable"} isPopUp={false}
                tableTitle={"Attack Information"} page={page}
                rowsPerPage={rowsPerPage} data={attacksInformation}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleChangePage={handleChangePage} isLoading={loadingData.table} />
              :
              <NoAttacksPage title={"No Attack information"}/>
          }
        </Box>

        <Popover
            open={customButton}
            onClose={()=>dispatch(setCustomButton(false))}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            sx={{mt:10}}
          >

          <DateTimePicker handleCustomDate={handleCustomDate}/>
      </Popover> 

     

      <PopupComponent 
        setFilterValue={setFilterValue}
        tableTitle={chartPopUp.label} tableId={chartPopUp.label}
        isOpen={chartPopUp.isOpen} handleClose={handleClosePopUp}
        rowsPerPage={chartPopUp.rowsPerPage} page={chartPopUp.page} data={chartTableInfo}
        handleChangeRowsPerPage={handleChangeChartRowsPerPage} handleChangePage={handleChangeChartPage} isLoading={loadingData.chart} />


      <SnackbarComponent/>

    </Box>

  )
}

export default MainContent
