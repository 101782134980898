import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import './pie.css';
import { Box, Typography } from '@mui/material';

const CUSTOM_PIE_COLORS = ["red", "green", "blue", "orange",'amber' ,"#F39C12", "#90A4AE", "#A3E4FF", "#D95B43", "#E74C3C", "#27AE60", "#FFC900", "#7F8C8D", "#F1F5F8"];
const TITLE_COLOR = "#1FA7D3";

function formatPercentage(d, dataset) {
  const count = d.count;
  const total = d3.sum(dataset, (d) => d.count);
  const percentage = ((count / total) * 100);
  const formattedPercentage = (percentage < 0.01) ? percentage.toFixed(3) : percentage.toFixed(2);
  return `${d.label} (${formattedPercentage}%)`;
}

const Pie = ({ handleClickPieSlice, title, dataset, id }) => {

  if (dataset?.length != 0){
    dataset?.sort((a, b) => b.count - a.count)
  }


  const [filteredDataset, setFilteredDataset] = useState(dataset);

  const chartRef = useRef<any>(null);
  const legendRef = useRef<any>(null);
  const [tooltip, setTooltip] = useState({ display: 'none', content: '', color: '', x: 0, y: 0, isLegend: false });

  const isSeverity = id === 'severity';

  const drawPieChart = () => {
    if (!chartRef.current || !chartRef?.current?.parentElement) {
      console.error('Chart container not available.');
      return;
    }

    const width = chartRef?.current?.parentElement?.offsetWidth;
    const height = chartRef?.current?.parentElement?.offsetHeight;
    const pieChartSize = Math.min(width, height / 1.8);
    const radius = pieChartSize / 2;

    const color = d3.scaleOrdinal().range(CUSTOM_PIE_COLORS);
    const arc = d3.arc().innerRadius(0).outerRadius(radius);

    const svg = d3.select(`#${id}`)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr('transform', `translate(${isSeverity ? width / 3 : width / 5},${height / 2})`);

    svg.selectAll('*').remove();

    const pie = d3.pie().value((d) => d?.count).sort(null);

    dataset?.forEach((d) => {
      d.count = +d?.count; // calculate count as we iterate through the data
      d['enabled'] = true; // add enabled property to track which entries are checked
    });

    let path = svg.selectAll('path')
      .data(pie(dataset))
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', (d) => color(d?.data?.label))
      .attr('stroke', '#fff')
      .style('cursor', isSeverity ? 'default' : 'pointer');

    path.on('mouseover', (event, d) => {
      d3.select(event.currentTarget).transition().duration(300).attr('d', d3.arc().innerRadius(0).outerRadius(radius * 1.1));
      const formattedPercentage = formatPercentage(d.data, dataset);
      setTooltip({
        display: 'block',
        content: `${formattedPercentage}`,
        color: color(d?.data?.label),
        x: event.pageX,
        y: event.pageY,
        isLegend: false
      });
    }).on('mousemove', (event, d) => {
      setTooltip(prev => ({ ...prev, x: event.pageX, y: event.pageY }));
    }).on('mouseout', (event) => {
      d3.select(event.currentTarget).transition().duration(300).attr('d', arc);
      setTooltip({ display: 'none', content: '', color: '', x: 0, y: 0, isLegend: false });
    });

    path.transition().duration(500).attrTween('d', (d) => {
      const interpolate = d3.interpolate({ startAngle: 0, endAngle: 0.4 * Math.PI }, d);
      return function (t) {
        return arc(interpolate(t));
      };
    });

    path.on('click', (event, d) => {
      handleClickPieSlice(d?.data?.label, id);
    });

    const legend = d3.select(legendRef.current)
      .classed("legend", true)
      .classed("legend-scrollable", dataset.length > 10)
      .style("float", "right")
      .style("height", "100%")
      .style("max-height", "18rem")
      .style('width', "100%")
      .style("overflow-y", "auto");

    const legendItems = legend.selectAll(".legend-item")
      .data(dataset)
      .enter()
      .append("div")
      .classed("legend-item", true)
      .style("color", d => color(d.label))
      .style("clear", "both");

      const colorBoxes = legendItems.append("div")
      .classed("color-box", true)
      .style("width", "20px")
      .style("height", "10px")
      .style("float", "left")
      .style("margin-right", "5px")
      .style("background-color", d => color(d.label))
      .on("click", function (event, re) {
        const legendItem = d3.select(event.currentTarget.parentNode);
                
        let enabled = true; // set enabled true to default
        // legendItem.classed('strikethrough', legendItem.classed('strikethrough'));

    
        var totalEnabled = d3.sum(dataset?.map(function (d) { // can't disable all options
          return d['enabled'] ? 1 : 0; // return 1 for each enabled entry. and summing it up
        }));
    
        if (legendItem.classed('strikethrough')) {
          // legendItem.classed('strikethrough', legendItem.classed('strikethrough'));
          legendItem.classed('strikethrough', false); // remove class if already present
        } else { // else
          if (totalEnabled < 2) return; // if less than two labels are flagged, exit
          // legendItem.classed('strikethrough', !legendItem.classed('strikethrough'));
          legendItem.classed('strikethrough', true); // remove class if already present
          enabled = false; // set enabled to false
          
        }

    
        pie.value(function (d) {
          console.log("d pie value ",d)
          if (d.label === re.label) {
            d['enabled'] = enabled; // if entry label matches legend label
          }
          return d['enabled'] ? d?.count : 0; // update enabled property and return count or 0 based on the entry's status
        });

        console.log("dataset ", dataset);
    
        path = path.data(pie(dataset)); // update pie with new data
        path.transition() // transition of redrawn pie
          .duration(500) // 
          .attrTween('d', (d) => { // 'd' specifies the d attribute that we'll be animating
            var interpolate = d3.interpolate(event.target, d); // this = current path element
    
            event.current = interpolate(0); // interpolate between current value and the new value of 'd'
            return function (t) {
      
              return arc(interpolate(t));
            };
          });
    
       // Update existing legend text with recalculated percentages
        legendItems.selectAll(".legend-text")
        .text(d => {
          const count = d['enabled'] ? d.count : 0; // Count based on enabled property
          const total = d3.sum(dataset, (d) => d.count);

          const percentage = (count / total) * 100; // Recalculate percentage
          const formattedPercentage = percentage < 0.01 ? percentage.toFixed(3) : percentage.toFixed(2); // Format percentage
          return `${d.label} (${formattedPercentage}%)`; // Return formatted label and percentage
        });
          });
  

    const legendText = legendItems.append("div")
      .style("float", "left")
      .text(d => `${formatPercentage(d, dataset)}`);
  }

  useEffect(() => {
    d3.selectAll(`#${id} > *`).remove();
    d3.select(window).on("resize", ()=>{d3.selectAll(`#${id} > *`).remove()
    drawPieChart()});
    drawPieChart()
  }, [dataset]);

  return (
    <Box
      sx={{
        width: { xs: "100%", lg: "49%", },
        height: "100%",
        overflow: "hidden",
        backgroundColor: 'white',
        borderRadius: 3,
        pt: "2%", pl: "0.8%",
      }}
    >
      <Typography sx={{ color: TITLE_COLOR, fontSize: "1.2rem", textAlign: "center" }} variant="h2">Attack by Category</Typography>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <svg
          id={id}
          ref={chartRef}
          style={{
            width: '100%',
            backgroundColor: "white",
            height: "20rem"

          }}></svg>
        <div ref={legendRef} className="legend"></div>
      </Box>

      <div className="pie-tooltip" style={{
        position: 'absolute',
        display: tooltip.display, left: tooltip.x, top: tooltip.y
      }}>
        {!tooltip.isLegend &&
          <span style={{
            backgroundColor: tooltip.color,
            width: '1rem', height: '1rem',
            display: 'inline-block', marginRight: '0.5rem'
          }}></span>}
        {tooltip.content}
      </div>

    </Box>
  )
};

export default Pie;
